<!-- 高级搜索区域 -->
<template>
  <div>
    <a-form ref="searchForm" :model="mySearchParam">
      <a-row>
        <a-col :span="7">
          <a-form-item
            label="账户:"
            name="accountId"
            :labelCol="formLayout2X2.labelCol"
            :wrapperCol="formLayout2X2.wrapperCol"
          >
            <a-select
              v-model:value="mySearchParam.accountId"
              placeholder="请选择一个账户"
              :disabled="roleId == 3"
            >
              <a-select-option :value="0">所有</a-select-option>
              <a-select-option
                v-for="(item, index) in myAccountInfoList"
                :key="index"
                :value="item.uid"
                >{{ item.uname }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="7">
          <a-form-item
            label="发送状态:"
            name="sendStatus"
            :labelCol="formLayout2X2.labelCol"
            :wrapperCol="formLayout2X2.wrapperCol"
          >
            <a-select v-model:value="mySearchParam.sendStatus">
              <a-select-option :value="0">所有</a-select-option>
              <a-select-option :value="1">待发送</a-select-option>
              <a-select-option :value="2">已发送</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="7">
          <a-form-item
            label="传输模式:"
            name="transMode"
            :labelCol="formLayout2X2.labelCol"
            :wrapperCol="formLayout2X2.wrapperCol"
          >
            <a-select v-model:value="mySearchParam.transMode">
              <a-select-option :value="0">所有</a-select-option>
              <a-select-option :value="1">普通</a-select-option>
              <a-select-option :value="2">加密</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="2" :offset="1">
          <a-button type="primary" @click="doSearch">
            <SearchOutlined />搜索
          </a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script lang='js'>
/* eslint-disable */
import { SearchOutlined } from "@ant-design/icons-vue";
import { reactive,toRefs,defineComponent,ref,onMounted,watch} from 'vue'

export default defineComponent({
  name: 'SearchArea',
  components: {
    SearchOutlined
  },
  // 接受父组件传递的值
  props:{
    searchParam:{
      type:Object,
      default: () => {},
    },
    accountInfoList:{
      type:Array,
      default: () => [],
    },
    rid:{
      type:Number,
      default: 0,
    }
  },  
  setup(props,context) {
    const searchForm = ref();
    const state = reactive({
      roleId: 0,
      myAccountInfoList: [],
      mySearchParam:{
        pageIndex:1,
        pageSize:10,
        accountId:0,
        sendStatus:0,
        transMode:0,
      },
      formLayout2X2:{
        labelCol: {
              span: 5,
            },
            wrapperCol: {
              span: 19,
            },
      }
    });
    onMounted(() => {    
      //initAccountInfoList();
      //context.emit("initSendInfoList",state.searchParam);
    });
    
    const doSearch = () =>{
      context.emit("initSendInfoList",state.mySearchParam);
    };

    watch(
      () => [props.searchParam,props.accountInfoList,props.rid],
      ([nv1,nv2,nv3]) => {
        state.mySearchParam = nv1;
        state.myAccountInfoList = nv2;
        state.roleId = nv3;
      },
      {
        immediate: true,
        deep: true
      }
    );

    const refData = toRefs(state);
    return {
        ...refData,
        searchForm,
        doSearch,
    }
  }
  });
</script>
<style lang='scss' scoped>
.ant-form-item {
  margin-bottom: 0px;
}
</style>